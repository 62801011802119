/* eslint-disable linebreak-style */
import React from 'react';
import '../../style/Layouts/footer.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  InstagramIcon, Logo, tiktokIcon, youtubeIcon, TwitterIcon,
} from '../../img';
import { setOpenLogin } from '../../state/AppState';

function Footer({ setOpenLoginState, giveaways }) {
  return (
    <div className="containerFooter">
      <div className="lineSeparatorFooter" />
      <div className="subContainerFooter">
        <img src={Logo} alt="Logo Footer" className="logoFooter" />
        <div className="linksFooter">
          <Link to="/sorteos/participar" href=".">Sorteos</Link>
          <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>
          <Link to="/politica-de-privacidad">Política de Privacidad</Link>
          <Link to="/preguntas-frecuentes" href=".">Preguntas Frecuentes</Link>
          <Link to="/quienes-somos" href=".">Sobre Nosotros</Link>
          <Link to="/contacto" href=".">Contacto</Link>
          {/* <Link to="/terminos-y-condiciones-sorteo" href=".">Términos y condiciones del sorteo</Link> */}
          <button type="button" className="createEvent" onClick={() => { setOpenLoginState(true); }}>Crear evento</button>
        </div>
        <div className="linksRedesFooter">
          <a href="https://www.instagram.com/voy.com.uy/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
            <img src={InstagramIcon} alt="Instagram Icon" />
          </a>
          <a href="https://twitter.com/VoyComUy?t=PeJKtci_oIpeCnxmFW8oqA&s=08" target="_blank" rel="noreferrer">
            <img src={TwitterIcon} alt="Twitter Icon" />
          </a>
          {/* <a href="https://www.tiktok.com/@voy.com.uy" target="_blank" rel="noreferrer">
            <img src={tiktokIcon} alt="Tiktok Icon" />
          </a>
          <a href="https://youtube.com/channel/UCJdqV45b_5cNsfUEnn_uS2Q" target="_blank" rel="noreferrer">
            <img src={youtubeIcon} alt="Youtube Icon" />
          </a> */}
        </div>
      </div>
      <div className="lineSeparatorFooter" />
      <div className="copyright">
        <span>Copyright © 2022 voy.</span>
        <a href="https://moonideas.com/" target="_blank" rel="noreferrer">
          <span>Powered by MoonIdeas</span>
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  openLogin: state.app.openLogin,
  giveaways: state.app.giveaways,
});
export default connect(mapStateToProps, {
  setOpenLoginState: setOpenLogin,
})(Footer);
