/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
import { React } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Banner from '../Advertisments/Banner';
import {
  BannerItau,
  BannerIntendencia,
  BannerOcaDesktop,
  BannerClubElPais,
  BannerLaDiaria,
  BannerSantander,
  BannerScotia,
  BannerBrou,
  BannerJetmar,
  BannerItauMobile,
  BannerIntendenciaMobile,
  BannerOcaMobile,
  BannerBrouMobile,
  BannerJetmarMobile,
  BannerClubElPaisMobile,
  BannerLaDiariaMobile,
  BannerSantanderMobile,
  BannerFidaeMobile,
  BannerFidae,
  BannerSorteos,
  BannerSorteosMobile,
  BannerSoctiaMobile,
  BannerWannaclapMobile,
  BannerWannaclap,
  BannerSodre,
  BannerSodreMobile,
} from '../../img';

function BannersHomeBottom() {
  const settingsBanner = {
    dots: false,
    infinite: true,
    speed: 2000,
    draggable: false,
    slidesToShow: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          lazyLoad: 'ondemand',
          autoplay: true,
          autoplaySpeed: 5000,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settingsBanner}>
        {/* <div className="bannerIntendencia desktopElement">
          <Link to="/sorteos/participar">
            <Banner banner={BannerSorteos} />
          </Link>
        </div> */}
        <div className="bannerIntendencia desktopElement">
          <Banner banner={BannerIntendencia} />
        </div>
        <div className="bannerIntendencia desktopElement">
          <Banner banner={BannerClubElPais} />
        </div>
        <div className="bannerIntendencia desktopElement">
          <Link to="/ver-eventos/3">
            <Banner banner={BannerBrou} />
          </Link>
        </div>
        {/* <div className="bannerIntendencia desktopElement">
          <a href="https://fidae.mec.gub.uy/" target="_blank" rel="noreferrer">
            <Banner banner={BannerFidae} />
          </a>
        </div> */}
        <div className="bannerIntendencia desktopElement">
          <a href="https://wannaclap.com/" target="_blank" rel="noreferrer">
            <Banner banner={BannerWannaclap} />
          </a>
        </div>
        <div className="bannerIntendencia desktopElement">
          <a href=" https://www.itau.com.uy/inst/?origen=voy&utm_source=voy&utm_medium=bannerhomepromocion&utm_campaign=voy" target="_blank" rel="noreferrer">
            <Banner banner={BannerItau} />
          </a>
        </div>
        <div className="bannerIntendencia desktopElement">
          <a
            href="https://ocacomercios.oca.com.uy/"
            target="_blank"
            rel="noreferrer"
          >
            <Banner banner={BannerOcaDesktop} />
          </a>
        </div>
        <div className="bannerIntendencia desktopElement">
          <Banner banner={BannerLaDiaria} />
        </div>
        <div className="bannerIntendencia desktopElement">
          <a
            href="https://misolicitud.santander.com.uy/?productType=OBT&utm_source=web&utm_medium=banner&utm_campaign=gourmet&utm_content=web_voy"
            target="_blank"
            rel="noreferrer"
          >
            <Banner banner={BannerSantander} />
          </a>
        </div>
        <div className="bannerIntendencia desktopElement">
          <a
            href="https://www.scotiabank.com.uy/Personas/Prestamos/Productos/prestamo-automotor?utm_source=plataforma_voy&utm_medium=banner_enlace&utm_campaign=prestamo_automotor&utm_content=banner_prestamo_automotor_enlace_plataforma_voy_oct22"
            target="_blank"
            rel="noreferrer"
          >
            <Banner banner={BannerScotia} />
          </a>
        </div>
        <div className="bannerIntendencia desktopElement">
            <a
              href="https://jetmar.com.uy/paquetes/?utm_source=voy.com.uy&utm_medium=referral&utm_campaign=VOY"
              target="_blank"
              rel="noreferrer"
            >
              <Banner banner={BannerJetmar} />
            </a>
          </div>
        {/* <div className="bannerIntendencia desktopElement">
          <Banner banner={BannerSodre} />
        </div> */}
      </Slider>

      <div className="bannersMobile mobileElement">
        <Slider {...settingsBanner}>
          {/* <div className="bannerIntendencia mobileElement">
            <Link to="/sorteos/participar">
              <Banner banner={BannerSorteosMobile} />
            </Link>
          </div> */}
          <div className="bannerIntendencia mobileElement">
            <a href="https://wannaclap.com/" target="_blank" rel="noreferrer">
              <Banner banner={BannerWannaclapMobile} />
            </a>
          </div>
          <div className="bannerIntendencia mobileElement">
            <Banner banner={BannerIntendenciaMobile} />
          </div>
          {/* <div className="bannerIntendencia mobileElement">
            <a href="https://fidae.mec.gub.uy/" target="_blank" rel="noreferrer">
              <Banner banner={BannerFidaeMobile} />
            </a>
          </div> */}
          <div className="bannerIntendencia mobileElement">
            <a href=" https://www.itau.com.uy/inst/?origen=voy&utm_source=voy&utm_medium=bannerhomepromocion&utm_campaign=voy" target="_blank" rel="noreferrer">
              <Banner banner={BannerItauMobile} />
            </a>
          </div>
          <div className="bannerIntendencia mobileElement">
            <a
              href="https://ocacomercios.oca.com.uy/"
              target="_blank"
              rel="noreferrer"
            >
              <Banner banner={BannerOcaMobile} />
            </a>
          </div>
          <div className="bannerIntendencia mobileElement">
            <Link to="/ver-eventos/3">
              <Banner banner={BannerBrouMobile} />
            </Link>
          </div>
          <div className="bannerIntendencia mobileElement">
            <Banner banner={BannerClubElPaisMobile} />
          </div>
          <div className="bannerIntendencia mobileElement">
            <Banner banner={BannerLaDiariaMobile} />
          </div>
          <div className="bannerIntendencia mobileElement">
            <a
              href="https://misolicitud.santander.com.uy/?productType=OBT&utm_source=web&utm_medium=banner&utm_campaign=gourmet&utm_content=web_voy"
              target="_blank"
              rel="noreferrer"
            >
              <Banner banner={BannerSantanderMobile} />
            </a>
          </div>
          <div className="bannerIntendencia mobileElement">
            <a
              href="https://www.scotiabank.com.uy/Personas/Prestamos/Productos/prestamo-automotor?utm_source=plataforma_voy&utm_medium=banner_enlace&utm_campaign=prestamo_automotor&utm_content=banner_prestamo_automotor_enlace_plataforma_voy_oct22"
              target="_blank"
              rel="noreferrer"
            >
              <Banner banner={BannerSoctiaMobile} />
            </a>
          </div>
          <div className="bannerIntendencia mobileElement">
            <a
              href="https://jetmar.com.uy/paquetes/?utm_source=voy.com.uy&utm_medium=referral&utm_campaign=VOY"
              target="_blank"
              rel="noreferrer"
            >
              <Banner banner={BannerJetmarMobile} />
            </a>
          </div>
          {/* <div className="bannerIntendencia mobileElement">
            <Banner banner={BannerSodreMobile} />
          </div> */}
        </Slider>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  sponsors: state.app.sponsors,
});
export default connect(mapStateToProps, null)(BannersHomeBottom);
